import React from 'react'

import ItemHistory from '../../components/views/history/itemHistory/itemHistory'
import { LayoutLoggedIn } from '../../components/views/layout'

export default function ItemHistoryPage() {
   return (
      <LayoutLoggedIn>
         <ItemHistory />
      </LayoutLoggedIn>
   )
}
